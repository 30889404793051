.projectHeader {
    padding: 0% 1%;
    margin-top: 0;
    font-size: 40px;
    color: hsla(31, 90%, 76%, 1);
}

.ProjectWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.ProjectCard {
    height: 500px;
    width: 400px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 10px 50px 10px;
    background-color: black;
}

.ProjectCard h4 {
    font-size: 18px;
    margin: 0;
    font-weight: 1000;
    padding: 1% 2%;
    background-color: #A0AEC0;
    color: white;
    border-radius: 10px;
}


.ProjectCard p {
    margin: 2% 0%;
    font-size: 14px;
    width: 100%;
    font-weight: 1000;

}

.ProjectDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 180px;
    margin: 0;
    padding: 2% 4%;
    border-radius: 0 0 8px 8px;
    border: 2px solid #2D3748;
    background: #2D3748;
    color: white;
}

.ProjectLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 30%;
}

.ProjectLinks h4 a {
    color: black;
}

.ProjectLinks h4:hover {
    color: black;
    background-color: hsla(31, 90%, 76%, 1);
}

.Link {
    float: right;
    color: #A0AEC0;
    height: 30px;
    width: 30px;
}

.Link:hover {
    color: hsla(31, 90%, 76%, 1);
    transition: all 0.2s ease-in-out;
}

.LinkDiv {
    display: flex;
}


@media screen and (min-width: 575px) {
    .ProjectDetails {
        height: 160px;
    }

    .ProjectCard h4 {
        font-size: 20px;
    }
}

@media screen and (min-width: 775px) {

    .projectHeader{
        font-size: 54px;
        margin: 3% 0;
        padding-left: 0%;
        padding-right: 0%;
    }

    .Link {
        height: 35px;
        width: 35px;
    }
}