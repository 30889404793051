.hero {
    background: #1A202C;
    color: white;
}


.HeroContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%
}

.desktopImage {
    display: none;
}

.imageContainer {
    width: 150px;
    margin: 5% 0;
}
.HeroImage {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.HeroContent h2 {
    font-size: 30px;
    font-weight: 1000;
    text-align: start;
    padding: 0 10%;
    color: #A0AEC0;
}

.HeroContent p {
    font-size: 20px;
    text-align: left;
    padding: 0 10%;
}

.HeroIntro {
    width: 100%;
}

.HeroLinks {
    display: flex;
    justify-content: center;
}

.Link {
    height: 40px;
    width: 40px;
    color:#A0AEC0;
    margin: 0 4px;
}

.Link:hover {
    color: hsla(31, 90%, 76%, 1);
    transition: all 0.1s ease-in-out;
}

.HeroContent .HeadingIntro {
    font-size: 60px;
    font-weight: 800;
    color: white;
    margin-top: 0;
    margin-bottom: 10%;
    color: hsla(31, 90%, 76%, 1);
}

.aboutStart {
    margin-bottom: 100px;
}

@media screen and (min-width: 775px) {
    .hero {
        height: 700px;
        padding: 0 8%;
    }


    .HeroContent {
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }

    .HeroContent h2 {
        padding: 0;
        font-size: 40px;
        font-weight: 400;
        margin: 0;
        text-align: start;
    }
    .HeroContent p {
        padding: 0;
        text-align: start;
        font-size: 22px;
        width: 90%;
        margin: 2% 0;
    }
    
    .HeroIntro {
        width: 100%;
    }

    .imageContainer {
        display: flex;
        align-items: center;
        width: 300px;
        margin: 3% 0;
    }
    
    .mobileImage {
        display: none;
    }

    .HeroImage {
        border-radius: 10%;
    }

    .Link {
        height: 55px;
        width: 55px;
    }

    .Link:hover {
        height: 57px;
        width: 57px;
    }

    .HeroLinks {
        justify-content: flex-start;
        margin-top: 4%;
        height: 70px;
    }

    .HeroContent .HeadingIntro {
        margin-bottom: 1%;
    }

    .HeroContent .SectionIntro {
        margin-bottom: 0%;
        width: 100%;
    }
}

@media screen and (min-width: 990px) {
    .hero {
        height: 80vh;
    }
}