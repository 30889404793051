.NavBtns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin-top: 10%;
}

.AboutLink {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4% 2%;
    width: 60%;
    font-size: 18px;
    border-radius: 40px;
    border: none;
    background-color: #2D3748;
    color: hsla(31, 90%, 76%, 1);
}

.btnArrow {
    margin-left: 10px;
}
.AboutLinkClicked {
    color: black;
    background-color: hsla(31, 90%, 76%, 1);
    transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 775px) {
    .AboutLink {
        padding: 12px;
        width: 200px;
    }
}