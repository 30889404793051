.NavBar {
    display: flex;
    justify-content: space-between;
    padding: 0 8%;
    position: sticky;
    z-index: 1;
    top: 0;
    width: 100%;
    font-size: 20px;
    color: white;
    background-color: #1A202C;
}

.NavBar a {
    flex: 0 50%;
    cursor: pointer;
    color: white;
}

.NavBar h1:hover {
    color: hsla(31, 90%, 76%, 1);
    transition: all 0.2s ease-in-out;
}

.NavBar .Navigation {
    display: flex;
    justify-content: center;
    position: sticky;
    z-index: 1;
    top: 0;
}
 
.NavBar .MobileNavigation {
    display: none;
}

.NavList {
    display: flex;
    justify-content: space-around;
}

.NavList li {
    display: flex;
    align-items: center;
    padding: 0 10%;
}

.NavList li a:hover, .NavList li span:hover {
    color:  hsla(31, 90%, 76%, 1);
    transition: all 0.3s ease-in-out;
}   

span {
    cursor: pointer;
}

@media screen and (max-width: 990px) {
    .NavBar {
        font-size: 100%;
    }

    .NavBar .Navigation {
        display: none;
    }

    .NavBar .MobileNavigation {
        display: flex;
        align-items: center;
    }

    .NavBar .MobileNavigation .Hamburger {
        position: absolute;
        right:7%;
        cursor: pointer;
    }

    .NavBar nav ul {
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: flex-end;
        top: 80px;
        right: 6.5%;
    }

    .NavBar nav ul li {
        float: right;
        margin: 5%;
        background-color:#2D3748;
        border-radius: 10px;
    }

    .NavBar nav ul li a {
        text-align: center;
    }

    .NavBar {
        opacity: 1;
    }

    .NavList li a:hover, .NavList li span:hover {
        color:  black;
        transition: none;
    }
}

@media screen and (max-width: 575px) {
    .NavBar a {
        flex: 0 60%;
    }

    .NavBar h1 {
        font-size: 28px;
        width: fit-content;
    }
}