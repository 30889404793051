.aboutHeading {
    padding: 0 1% 2% 0%;
    margin-top: 0;
    color: hsla(31, 90%, 76%, 1);
    font-size: 40px;
}

.aboutContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
}

.introCode {
    display: none;
}

.IntroBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.IntroBody p {
    color: white;
    padding: 1%;
}

.projectStart {
    margin-bottom: 110px;
}

.logoContainer {
    margin-top: 30px;
}

.logo {
    font-size: 50px;
    margin-right: 1%;
}

.logo:hover {
    color: hsla(31, 90%, 76%, 1);
}

@media screen and (min-width: 775px) {
    .introCode {
        display: flex;
        width: 45%;
        margin-right: 4%;
        border-radius: 10px;
        background:#000;
    }

    .aboutHeading{
        font-size: 54px;
        margin: 0;
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 5%;
    }

    .IntroBody p {
        font-size: 20px;
        padding-left: 0%;
        padding-right: 0%;
        margin: 0;
    }

    .aboutContainer {
        flex-direction: row;
        margin-bottom: 8%;
    }

    .IntroBody {
        width: 50%;
    }

    .projectStart {
        margin-bottom: 200px;
    }
}

@media screen and (min-width: 990px) {
    .introCode {
        display: flex;
        width: 45%;
        margin-right: 4%;
        border-radius: 10px;
        background:#000;
    }
}