.AboutBody {
    color: white;
}

.AboutBody h3 {
    color: hsla(31, 90%, 76%, 1);
}

.TechnologyList li{
    list-style: none;
    cursor: default;
}

.WorkLink {
    cursor: pointer;
    float: right;
    font-style:italic;
}

.WorkLink:hover {
    color: hsla(31, 90%, 76%, 1);
    transition: all 0.3s ease-in-out;
}

.NavBtns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.AboutLink {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 4%;
    width: 60%;
    margin: 9px 0 ;
    font-style: italic;
    font-size: 18px;
    border-radius: 40px;
    border: none;
    background-color: #2D3748;
    color: hsla(31, 90%, 76%, 1);
}

.btnArrow {
    margin-left: 10px;
}
.AboutLink:hover {
    color: black;
    background-color: hsla(31, 90%, 76%, 1);
    transition: all 0.3s ease-in-out;
}

.projectStart {
    margin-bottom: 100px;
}

@media screen and (min-width: 775px) {

    .AboutLink {
        padding: 12px;
        width: 30%;
    }
}

@media screen and (min-width: 990px) {
    .AboutBody h3{
        font-size: 40px;
        margin: 0;
    }
    
    .AboutBody h4{
        font-size: 40px;
    }

    .AboutBody p {
        font-size: 20px;
    }

    .AboutBody ul {
        font-size: 20px;
    }

    .AboutBody ul li:hover {
        color: hsla(31, 90%, 76%, 1);
    }

    .SkillDiv {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
    }

    .SkillBox {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }

    .projectStart {
        margin-bottom: 0px;
    }
}