.Footer {
    background-color: #1A202C;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2% 1%;
}

.Footer p {
    font-size: smaller;
    font-style: italic;
    text-align: center;
    margin: 1px;
}


.EmailButton {
    margin: 0 auto;
}

.EmailButton button{
    width: 150px;
    height: 30px;
    background: #6589f7;
    border-radius: 2.5px;
    border: none;
    font-family: "Manrope",sans-serif;
    color:white;
    margin-bottom: 2%;
    font-size: 18px;
}

.EmailButton button:hover {
    background: #4d78f8;
    cursor: pointer;
}
